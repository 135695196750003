const contactStyles = {
    contactUs: {
        fontFamily: "'Arial', sans-serif",
        color: '#333',
        paddingBottom: '2rem'
    },
    heroSection: {
        borderRadius:`1vh ! important`,
        border: "solid 1px grey",
        backgroundColor: '#141c24',
        padding: '3rem 0',
    },
    heroTitle: {
        color:'white',
        fontSize: '2.5rem',
        marginBottom: '0.5rem'
    },
    heroSubtitle: {
        fontSize: '1.25rem',
        color: '#6c757d'
    },
    infoSection: {
        padding: '2rem 0'
    },
    infoCard: {
        borderRadius:`1vh ! important`,
        border: "solid 0.5 #f0fcfc",
        background: "#f0fcfc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.20)",
        backdropFilter:'blur(10px)',
        marginBottom: '1rem'
    },
    formSection: {
        borderRadius:`1vh ! important`,
        border: "solid 1px black",
        padding:'20px',
        background: "#f0fcfc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.20)",
        backdropFilter:'blur(10px)',
        marginBottom: '1rem'
    },
    submitButton: {
        marginTop: '1rem'
    },
    mapSection: {
        padding: '2rem 0'
        // Add styles for the map section if using a map
    },
    footer: {
        backgroundColor: '#141c24',
        padding: '1rem 0',
        marginTop: '2rem',
        color:'white'
    }
};

export default contactStyles;
