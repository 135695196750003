import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import aboutStyles from "../../Styles/aboutStyles";
const About = () => {
    return (
        <div style={aboutStyles.aboutUs}>
            {/* Mission Statement Section */}
            <div style={aboutStyles.missionSection}>
                <Container className="text-center">
                    <h1 style={aboutStyles.missionTitle}>Our Mission</h1>
                    <p style={aboutStyles.missionText}>
                        At ASSOY, we are committed to delivering top-notch software solutions that empower businesses to thrive in the digital era.
                    </p>
                </Container>
            </div>

            {/* Company History Section */}
            <Container style={aboutStyles.historySection} className="my-5">
                <h2 className="text-center mb-4">Our Story</h2>
                <p style={aboutStyles.historyText}>
                    Founded in 2024, ASSOY started with a small team of passionate developers with a vision to transform businesses through innovative technology. Over the years, we have grown into a leading software development company, serving clients across various industries with our cutting-edge solutions.
                </p>
            </Container>

            {/* Team Section */}
            {/* <Container style={aboutStyles.teamSection} className="my-5">
                <h2 className="text-center mb-4">Meet Our Team</h2>
                <Row>
                    <Col md={4}>
                        <Card style={aboutStyles.teamCard}>
                            <Image src="[TeamMember1.jpg]" roundedCircle style={aboutStyles.teamImage} />
                            <Card.Body>
                                <Card.Title>John Doe</Card.Title>
                                <Card.Text>CEO & Founder</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={aboutStyles.teamCard}>
                            <Image src="[TeamMember2.jpg]" roundedCircle style={aboutStyles.teamImage} />
                            <Card.Body>
                                <Card.Title>Jane Smith</Card.Title>
                                <Card.Text>CTO</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={aboutStyles.teamCard}>
                            <Image src="[TeamMember3.jpg]" roundedCircle style={aboutStyles.teamImage} />
                            <Card.Body>
                                <Card.Title>Michael Brown</Card.Title>
                                <Card.Text>Lead Developer</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container> */}
        </div>
    );
};

export default About;
