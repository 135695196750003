const homeStyles = {
    home: {
        fontFamily: "'Arial', sans-serif",
    },
    heroSection: {
        background: "#141c24",
        color: "white",
        padding: "80px 0",
    },
    heroTitle: {
        fontSize: "2.5rem",
        marginBottom: "20px",
    },
    heroSubtitle: {
        fontSize: "1.25rem",
        marginBottom: "40px",
    },
    heroButton: {
        padding: "10px 30px",
        fontSize: "1.1rem",
    },
    servicesSection: {
        textAlign: "center",
    },
    serviceCard: {
        borderRadius:`1vh ! important`,
        border: "solid 0.5 #f0fcfc",
        background: "#f0fcfc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.20)",
        marginBottom: "30px",
        backdropFilter:'blur(10px)'
    },
    testimonialsSection: {
        // background: "#f1f1f1",
        padding: "50px 0",
    },
    testimonialCard: {
        // border: "none",
        // background: "white",
        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        // marginBottom: "30px",
        borderRadius:`1vh ! important`,
        border: "solid 0.5 #f0fcfc",
        background: "#f0fcfc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.20)",
        marginBottom: "30px",
        backdropFilter:'blur(10px)'
    },
    ctaSection: {
        backgroundColor: '#141c24',
        padding: '1rem 0',
        marginTop: '2rem',
        color:'white'
    },
};

export default homeStyles;
