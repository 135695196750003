import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import servicesStyles from "../../Styles/ServicesStyles";
const Services = () => {
    return (
        <div style={servicesStyles.services}>
            {/* Intro Section */}
            <div style={servicesStyles.introSection}>
                <Container className="text-center">
                    <h1 style={servicesStyles.introTitle}>Our Services</h1>
                    <p style={servicesStyles.introText}>
                        We offer a wide range of software development services tailored to meet your business needs. Explore our offerings below.
                    </p>
                </Container>
            </div>

            {/* Services Section */}
            <Container style={servicesStyles.servicesSection} className="my-5">
                <Row>
                    <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-solid fa-globe"></i></div>
                                <Card.Title>Web Development</Card.Title>
                                <Card.Text>
                                We combine innovative technology and artistic creativity to craft stunning web designs that draw in your customers. Our hallmark is building 100% secure and robust websites for your business and services. Trust us to deliver a seamless blend of beauty and security in each and every project .
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-solid fa-laptop-code"></i></div>
                                <Card.Title>Software Development</Card.Title>
                                <Card.Text>
                                We offer top-notch software development services with comprehensive, robust, and scalable solutions tailored to your needs. Our approach ensures that you receive software designed to meet your specific requirements and grow with your business. Trust us to deliver high-quality solutions that drive success.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-solid fa-cart-shopping"></i></div>
                                <Card.Title>E-Commerce Solutions</Card.Title>
                                <Card.Text>
                                We develop reliable, scalable, and fast e-commerce solutions customized for your small and medium-sized enterprises. Our tailored approach ensures your business gets the exact tools it needs to thrive in the online marketplace. Partner with us for e-commerce solutions that support your growth and success.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                  
                </Row>
                <Row>
                <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-sharp-duotone fa-solid fa-rectangle-ad"></i></div>
                                <Card.Title>Digital Marketing</Card.Title>
                                <Card.Text>
                                With years of experience in the IT industry, we have gained valuable insights into navigating the fast-evolving digital landscape to deliver high-quality digital marketing services. Our expertise ensures that we stay ahead of trends and provide innovative solutions tailored to your business needs. Trust us to enhance your online presence and drive success in the digital world.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-solid fa-magnifying-glass"></i>
                            </div>
                                <Card.Title>SEO</Card.Title>
                                <Card.Text>
                                We manage your business's rankings across various platforms, including search engines like Google and Yahoo, and social media sites such as Facebook, Twitter, LinkedIn, and YouTube. Our global reach ensures that your business gains visibility and prominence on the most important digital platforms. Trust us to enhance your online presence and drive engagement worldwide.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-solid fa-headset"></i>
                            </div>
                                <Card.Title>Support and Services</Card.Title>
                                <Card.Text>
                                We offer a full range of support and services, including website maintenance, which covers security, server migration , content backups, updates, and web hosting , data cleaning  , digital marketing , seo , and more. Our comprehensive solutions ensure your website remains secure, up-to-date, and functioning smoothly. Rely on us for all your website maintenance needs.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-solid fa-mobile-screen"></i></div>
                                <Card.Title>UI/UX Design</Card.Title>
                                <Card.Text>
                                    We craft user-centric designs that enhance usability and provide a delightful experience for your customers.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-solid fa-magnifying-glass-chart"></i></div>
                                <Card.Title>Data Analytics</Card.Title>
                                <Card.Text>
                                    Our data experts help you make informed decisions by providing actionable insights through advanced analytics.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={servicesStyles.serviceCard}>
                            <Card.Body>
                            <div><i  style={{fontSize:'29px'}} class="fa-solid fa-screwdriver-wrench"></i></div>
                                <Card.Title>Custom Software Development</Card.Title>
                                <Card.Text>
                                    We develop tailored software solutions that address your unique business challenges and requirements.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}
            </Container>
        </div>
    );
};

export default Services;
