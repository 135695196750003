import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import homeStyles from "../../Styles/homeStyles";

const Home = () => {
    return (
        <div style={homeStyles.home}>
            {/* Hero Section */}
            <div style={homeStyles.heroSection}>
                <Container className="text-center">
                    <h1 style={homeStyles.heroTitle}>Empowering Your Business with Cutting-Edge Software Solutions</h1>
                    <p style={homeStyles.heroSubtitle}>We deliver scalable and robust software solutions tailored to your business needs.</p>
                    <Button variant="primary" size="lg" style={homeStyles.heroButton}>Get Started</Button>
                </Container>
            </div>

            {/* Services Section */}
            <Container style={homeStyles.servicesSection} className="my-5">
                <h2 className="text-center mb-4">Our Services</h2>
                <Row>
                    <Col md={4}>
                        <Card style={homeStyles.serviceCard}>
                            <Card.Body>
                                <div><i  style={{fontSize:'29px'}} class="fa-solid fa-laptop-code"></i></div>
                                <Card.Title>Web Development</Card.Title>
                                <Card.Text>Build modern and responsive websites that drive your business forward.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={homeStyles.serviceCard}>
                            <Card.Body>
                                <div><i  style={{fontSize:'29px'}} class="fa-solid fa-mobile-screen"></i></div>
                                <Card.Title>Mobile App Development</Card.Title>
                                <Card.Text>Create intuitive and engaging mobile applications for iOS and Android platforms.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={homeStyles.serviceCard}>
                            <Card.Body>
                                <div><i  style={{fontSize:'29px'}} class="fa-solid fa-cloud"></i><i class="fa-solid fa-cloud"></i>
</div>
                                <Card.Title>Cloud Solutions</Card.Title>
                                <Card.Text>Leverage cloud technologies to scale your business and improve efficiency.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Testimonials Section */}
            <Container style={homeStyles.testimonialsSection} className="my-5">
                <h2 className="text-center mb-4">What Our Clients Say</h2>
                <Row>
                    <Col md={4}>
                        <Card style={homeStyles.testimonialCard}>
                            <Card.Body>
                                <Card.Text>"The team at ASSOY transformed our business with their innovative solutions."</Card.Text>
                                <Card.Footer className="text-muted">Ashish</Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={homeStyles.testimonialCard}>
                            <Card.Body>
                                <Card.Text>"Their expertise in mobile app development is unmatched. Highly recommend!"</Card.Text>
                                <Card.Footer className="text-muted">Bhaskar</Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card style={homeStyles.testimonialCard}>
                            <Card.Body>
                                <Card.Text>"Excellent cloud solutions that have significantly improved our operations."</Card.Text>
                                <Card.Footer className="text-muted">Mayur</Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <div style={homeStyles.ctaSection}>
            <p className="text-center mb-0">© 2024 ASSOY. All rights reserved.</p>
            </div>

        </div>
    );
};

export default Home;
