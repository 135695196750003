import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainHome from './Components/Home/MainHome';
import HeaderNavbar from './Components/HeaderNavbar';
import Services from './Components/Services/Services';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';

function App() {
  return (
    <>
      <Router>
        <HeaderNavbar />
        <Routes>
          <Route path="/services" element={<Services />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
