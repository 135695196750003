const aboutStyles = {
    aboutUs: {
        fontFamily: "'Arial', sans-serif",
    },
    missionSection: {
        background: "#141c24",
        color: "white",
        padding: "60px 0",
    },
    missionTitle: {
        fontSize: "2.5rem",
        marginBottom: "20px",
    },
    missionText: {
        fontSize: "1.25rem",
        maxWidth: "800px",
        margin: "0 auto",
    },
    historySection: {
        color:'black',
        textAlign: "center",
        borderRadius:`1vh ! important`,
        border: "solid 1px black",
        padding:'20px',
        background: "#f0fcfc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.20)",
        backdropFilter:'blur(10px)',
        marginBottom: '1rem'
    },
    historyText: {
        fontSize: "1.1rem",
        maxWidth: "900px",
        margin: "0 auto",
        color: "#141c24",
    },
    teamSection: {
        textAlign: "center",
    },
    teamCard: {
        border: "none",
        background: "#f8f9fa",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "30px",
        padding: "20px",
    },
    teamImage: {
        width: "150px",
        height: "150px",
        marginBottom: "15px",
    }
};

export default aboutStyles;
