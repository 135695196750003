const servicesStyles = {
    services: {
        fontFamily: "'Arial', sans-serif",
    },
    introSection: {
        background: "#141c24",
        color: "white",
        padding: "60px 0",
    },
    introTitle: {
        fontSize: "2.5rem",
        marginBottom: "20px",
    },
    introText: {
        fontSize: "1.25rem",
        maxWidth: "800px",
        margin: "0 auto",
    },
    servicesSection: {
        textAlign: "center",
    },
    serviceCard: {
        borderRadius:`1vh ! important`,
        border: "solid 0.5 #f0fcfc",
        background: "#f0fcfc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.20)",
        marginBottom: "30px",
        backdropFilter:'blur(10px)'
    },
};

export default servicesStyles;
