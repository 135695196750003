import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import contactStyles from "../../Styles/ContactStyles";
const Contact = () => {
    return (
        <div style={contactStyles.contactUs}>
            {/* Hero Section */}
            <div style={contactStyles.heroSection}>
                <Container className="text-center">
                    <h1 style={contactStyles.heroTitle}>Get in Touch with Us</h1>
                    <p style={contactStyles.heroSubtitle}>We’re here to assist you with any questions or inquiries.</p>
                </Container>
            </div>

            {/* Contact Information Section */}
            <Container style={contactStyles.infoSection} className="my-5">
                <Row>
                    <Col md={6}>
                        <Card style={contactStyles.infoCard}>
                            <Card.Body>
                                <Card.Title>Our Office</Card.Title>
                                <Card.Text>
                                    66 Dalal Street <br />
                                   Solapur
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card style={contactStyles.infoCard}>
                            <Card.Body>
                                <Card.Title>Contact Details</Card.Title>
                                <Card.Text>
                                    Email: assoy.startup@gmail.com<br />
                                    Phone: 7249443563 / 7559222939
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Contact Form Section */}
            <Container style={contactStyles.formSection} className="my-5">
                <h2 className="text-center mb-4">Send Us a Message</h2>
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter your name" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter your email" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="formSubject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control type="text" placeholder="Enter the subject" />
                    </Form.Group>
                    <Form.Group controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={4} placeholder="Your message here" />
                    </Form.Group>
                    <Button variant="primary" type="submit" style={contactStyles.submitButton}>
                        Send Message
                    </Button>
                </Form>
            </Container>

            {/* Map Section (Optional) */}
            <div style={contactStyles.mapSection}>
                {/* You can integrate a map component or iframe here */}
            </div>

            {/* Footer Section */}
            <div style={contactStyles.footer}>
                <Container>
                    <p className="text-center mb-0">© 2024 ASSOY. All rights reserved.</p>
                </Container>
            </div>
        </div>
    );
};

export default Contact;
