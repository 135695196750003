import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Images from '../Utils/Images';

const HeaderNavbar = () => {
  return (
    <Navbar 
      expand="lg" // Makes the navbar responsive
      style={{
        margin: '10px',
        borderRadius: '30px 50px 20px 50px',
       backgroundColor:'#fcec04'
      }}
    >
      <Container >
        <Navbar.Brand as={NavLink} to="/home">
          {/* <img
          style={{border:'1px solid black'}}
            src={Images.logo} 
            width="100px"
            height="50"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          /> */}
          <div style={{

            border: '1px solid black',
            width: '100px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Arial, sans-serif',
            fontSize: '18px',               
            fontWeight: 'bold',             
            color: 'white',               
            letterSpacing: '1px',     
            backgroundColor:'black'       
          }}>
            ASSOY
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} to="/home" style={{ color: 'black', fontWeight: 'bold' }}>Home</Nav.Link>
            <Nav.Link as={NavLink} to="/services" style={{ color: 'black', fontWeight: 'bold' }}>Services</Nav.Link>
            <Nav.Link as={NavLink} to="/about" style={{ color: 'black', fontWeight: 'bold' }}>About</Nav.Link>
            <Nav.Link as={NavLink} to="/contact" style={{ color: 'black', fontWeight: 'bold' }}>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderNavbar;
